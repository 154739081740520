import { Grid } from '@mui/material';
import { NavigationDropdown } from '../dropdowns/NavigationDropdown';
import pooks from '../../assets/images/pooksBlack.png';
import { buy, socials, story, more, loyalty } from "./NavItems";
import './Navbar.scss';

type NavbarProps = {
  pageBackgroundColor?: string;
}

const Navbar = ( { pageBackgroundColor }: NavbarProps ) => {

  const handleRedirect = (href: string) => {
    window.location.href = href;
  }

  return (
    <Grid container className="navbar-container">
      <Grid container className="navbar-content-container">
        <Grid container className="pooks-header-logo-container" onClick={() => handleRedirect("/")}>
          <img src={pooks} alt="pooks logo" className="pooks-header-logo"/>
        </Grid>
        <Grid className="navbar-content">
          <NavigationDropdown title="our story" items={story} backgroundColor={pageBackgroundColor}/>
          <NavigationDropdown title="loyalty" items={loyalty} backgroundColor={pageBackgroundColor}/>
          <NavigationDropdown title="Buy" items={buy} backgroundColor={pageBackgroundColor}/>
          <NavigationDropdown title="more" items={more} backgroundColor={pageBackgroundColor}/>
          <NavigationDropdown title="connect" items={socials} backgroundColor={pageBackgroundColor}/>
          <span className="gallery-button-container" style={ pageBackgroundColor ? { backgroundColor: pageBackgroundColor, backdropFilter: "blur(3px)" } : {} }>
            <a className="gallery-redirect-button" target="_blank" rel="noreferrer" href="https://shop.pooks.io/">Shop</a>
          </span>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Navbar;
