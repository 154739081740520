import { FC, useState } from 'react';
import { Grid, Divider } from '@mui/material';
import { HamburgerMenuIcon } from './HamburgerMenuIcon';
import { ExpandableComponent } from '../dropdowns/ExpandableComponent';
import { buy, socials, story, more, loyalty } from './NavItems';
import pooks from '../../assets/images/pooks.png';
import './HamburgerMenu.scss';

type HamburgerMenuProps = {
  open?: boolean;
  toggleMenu?: () => void;
}

const HamburgerMenu: FC<HamburgerMenuProps> = ({ open, toggleMenu }: HamburgerMenuProps) => {
  const [isOpen, setIsOpen] = useState(open ?? false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    toggleMenu && toggleMenu();
  };

  return (
    <Grid className="hamburger-menu-container">
      <div onClick={handleClick}>
        <HamburgerMenuIcon open={isOpen}/>
      </div>
      <Grid
        container
        className={`hamburger-menu ${isOpen && 'open'}`}
        >
        <Grid className="content-container">
          <div className="pooks-redirect-container">
            <img src={pooks} className="top-pooks-logo" alt="pooks logo" onClick={() => window.location.href = "/"} />
          </div>
          <div className="expandables-container">
            <ExpandableComponent title="our story" items={story}/>
            <Divider className="divider"/>
            <ExpandableComponent title="loyalty" items={loyalty}/>
            <Divider className="divider"/>
            <ExpandableComponent title="Buy" items={buy}/>
            <Divider className="divider"/>
            <ExpandableComponent title="more" items={more}/>
            <Divider className="divider"/>
            <ExpandableComponent title="connect" items={socials}/>
            <Divider className="divider"/>
            <a className="gallery-redirect-button" target="_blank" rel="noreferrer" href="https://shop.pooks.io/">Shop</a>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
};
export default HamburgerMenu;