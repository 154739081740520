import { FC } from 'react';
import './HamburgerMenuIcon.scss';

interface HamburgerMenuIconProps {
  open: boolean;
}

export const HamburgerMenuIcon: FC<HamburgerMenuIconProps> = ({ open }: HamburgerMenuIconProps) => {
  return (
    <div className={`hamburger ${open && 'open'}`}>
      <div className="icon"></div>
    </div>
  )
}
