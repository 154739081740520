import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./styles/Home.css";
import PageLayout from './layouts/PageLayout';
import { useMediaQuery, useTheme } from '@mui/material';
import EmbeddedPage from './layouts/EmbeddedPage';
import CircularProgress from '@mui/material/CircularProgress';

const Pooks = React.lazy(() => import('./pages/pooks/Pooks'));
const PooksMobile = React.lazy(() => import('./pages/pooks/mobile/PooksMobile'));
const LandingPage = React.lazy(() => import('./pages/landing/LandingPage'));
const Clubs = React.lazy(() => import('./pages/clubs/Clubs'));
const About = React.lazy(() => import('./pages/about/About'));

const Fallback = () => (
  <div style ={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <CircularProgress color="inherit" />
  </div>
)

const App = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<PageLayout backgroundColor="#F5F3F06E"><Suspense fallback={<Fallback />}><LandingPage /></Suspense></PageLayout>}/>
        <Route
          path="/museum"
          element={<EmbeddedPage title="Museum" src="https://profiles.pooks.io/museum?sidebar=false" backgroundColor="#f5f3f06e"/>}
        />
        <Route
          path="/brand"
          element={<EmbeddedPage title="Brand Kit" src="https://profiles.pooks.io/brand?sidebar=false" backgroundColor="#f5f3f06e"/>}
        />
        <Route
          path="/pooks"
          element={
            <PageLayout backgroundColor="#F5F3F04a">
              <Suspense fallback={<Fallback />}>
                {isMobile ? <PooksMobile /> : <Pooks />}
              </Suspense>
            </PageLayout>
          }
        />
        <Route
          path="/clubs"
          element={<PageLayout backgroundColor="#F5F3F04a"><Suspense fallback={<Fallback />}><Clubs /></Suspense></PageLayout>}
        />
        <Route path="/about" element={<PageLayout backgroundColor="#F5F3F04a"><Suspense fallback={<Fallback />}><About /></Suspense></PageLayout>} />
      </Routes>
    </Router>
  );
}

export default App;
