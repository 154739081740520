import { twitterIcon, discordIcon, instagramIcon } from "../assets/icon-library/icon-library";
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export type IconTypes = 'twitter' | 'discord' | 'instagram';

type SocialMediaIconProps = {
  type: IconTypes;
  className?: string;
};

const TwitterIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1227"
  >
    <path d={twitterIcon.info}/>
  </svg>,
  'Twitter',
);

const DiscordIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <path d={discordIcon.info}/>
  </svg>,
  'Discord',
);

const InstagramIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <path d={instagramIcon.info}/>
  </svg>,
  'Instagram',
);

export const SocialMediaIcon = ( { type, className }: SocialMediaIconProps ) => {
  switch (type) {
    case 'twitter':
      return <TwitterIcon viewBox='0 0 19 24' className={className} />
    case 'instagram':
      return <InstagramIcon className={className} />
    case 'discord':
      return <DiscordIcon className={className} />
  }
};