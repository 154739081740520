import React, { useState } from "react";
import Navbar from "../components/navigation/Navbar";
import { useMediaQuery, useTheme } from "@mui/material";
import HamburgerMenu from "../components/navigation/HamburgerMenu";

type PageLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  backgroundColor?: string;
};

const PageLayout = ({ children, backgroundColor }: PageLayoutProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  return (
    <>
      { isMobile ? <HamburgerMenu open={isMenuOpen} toggleMenu={toggleMenu} /> : <Navbar pageBackgroundColor={backgroundColor}/>}
      {children}
    </>
  )
};

export default PageLayout;