import { NavItem } from '../dropdowns/NavigationDropdown';

export const socials: NavItem[] = [
  {
    title: 'Twitter',
    to: 'https://twitter.com/pooksofficial',
    icon: "twitter"
  },
  {
    title: 'Instagram',
    to: 'https://www.instagram.com/wearepooks/',
    icon: "instagram"
  },
  {
    title: 'Discord',
    to: 'http://discord.gg/rYNVnjAgnW',
    icon: "discord"
  }
]

export const story: NavItem[] = [
  {
    title: "About Us",
    to: "/about",
    isRoute: true
  },
  {
    title: "pooks",
    to: "/pooks",
    isRoute: true
  }
];

export const more: NavItem[] = [
  {
    title: "Clubs",
    to: "/clubs",
    isRoute: true
  },
  {
    title: "Brand Kit",
    to: "/brand",
    isRoute: true
  },
  {
    title: "Museum",
    to: "/museum",
    isRoute: true
  },
  {
    title: "Barbershop",
    to: "https://profiles.pooks.io/barbershop",
    isRoute: true
  }
];

export const buy: NavItem[] = [
  {
    title: "pooks (Magic Eden)",
    to: "https://magiceden.io/marketplace/pooks"
  },
  {
    title: "pooks (Tensor)",
    to: "https://www.tensor.trade/trade/pooks"
  }
]

export const loyalty: NavItem[] = [
  {
    title: "Profile",
    to: "https://profiles.pooks.io/",
    isRoute: true
  },
  {
    title: "Leaderboard",
    to: "https://profiles.pooks.io/leaderboard",
    isRoute: true
  },
  {
    title: "Documentation",
    to: "https://medium.com/@pooksofficial/pooks-loyalty-6c372b8e0e45"
  }
]
