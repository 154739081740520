import React, { FC, useState } from 'react';
import './NavigationDropdown.scss';
import { IconTypes, SocialMediaIcon } from '../../utils/SocialMediaIcon';
import createSvgIcon from '@mui/material/utils/createSvgIcon';
import { expandIcon } from '../../assets/icon-library/icon-library';

export const ExpandIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 5"
  >
    <path d={expandIcon.info}/>
  </svg>,
  "Expand"
)

export type NavItem = {
  title: string;
  to: string;
  icon?: string;
  isRoute?: boolean;
  newTab?: boolean;
}

interface NavigationDropdownProps {
  id?: string;
  title: string;
  items: NavItem[];
  backgroundColor?: string;
}

export const NavigationDropdown: FC<NavigationDropdownProps> = ({
  id = 'navigation-dropdown',
  title,
  items,
  backgroundColor
}: NavigationDropdownProps ) => {
  const [isOpen, setIsOpen] = useState(false);

  const getSocialIcon = (icon: string) => (
    <SocialMediaIcon type={icon as IconTypes} className={`nav-item-socials-icon ${icon}`} />
  )

  return (
    <div
      className="navigation-dropdown"
      id={id}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      style={ backgroundColor ? { backgroundColor: backgroundColor, backdropFilter: "blur(3px)" } : {}}
    >
      <div className="dropdown-header">
        <div className="dropdown-title">{title}</div>
        <span className={`dropdown-icon ${isOpen ? 'open' : 'closed'}`}><ExpandIcon className={`${id}-expand-more-icon`} /></span>
      </div>
      {isOpen && (
        <ul className="dropdown-menu">
          <div className="menu-container">
            {items.map((item, index) => (
              <>              
                <li className="menu-item" key={index}>
                  <a className="item-text" target={item.isRoute ? "_self" : "_blank"} rel="noopener noreferrer" href={item.to}>
                    {item.icon && getSocialIcon(item.icon)}
                    {item.title}
                  </a>
                </li>
                {index !== items.length - 1 && <div className="divider-container"><span className="divider"/></div>}
              </>
            ))}
          </div>
        </ul>
      )}
    </div>
  );
};

