import { FC, useState } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import { ExpandIcon, NavItem } from './NavigationDropdown';
import './ExpandableComponent.scss';
import { IconTypes, SocialMediaIcon } from '../../utils/SocialMediaIcon';

interface ExpandableComponentProps {
  title: string;
  items: NavItem[];
}

export const ExpandableComponent:FC<ExpandableComponentProps> = ({
  title,
  items
}: ExpandableComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  const getSocialIcon = (icon: string) => (
    <SocialMediaIcon type={icon as IconTypes} className={`expandable-component-socials-icon ${icon}`} />
  )

  return (
    <Box className="expandable-component-container">
      <button className="expand-button" onClick={handleClick}>
        <h3 className="expandable-title">{title}</h3>
        <ExpandIcon className={`expand-more-icon ${isOpen && 'open'}`}/>
      </button>
      <Collapse className="collapse" orientation="vertical" in={isOpen}>
        <div className="expand-content">
          {items.map(( item, idx ) => (
            <Grid key={`item-${item.title}-${idx}`} item className='expand-item'>
              <a className='expand-item-text' href={item.to} target={item.isRoute ? "_self" : "_blank"} rel="noopener noreferrer">
                {item.icon && getSocialIcon(item.icon)}
                {item.title}
              </a>
            </Grid>
          ))}
        </div>
      </Collapse>
    </Box>
  );
};
