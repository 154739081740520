import PageLayout from "./PageLayout";
import "./EmbeddedPage.scss"

type EmbeddedPageProps = {
  src: string;
  title: string;
  backgroundColor?: string;
};

export const EmbeddedPage = ({ src, title, backgroundColor }: EmbeddedPageProps) => {
  return (
    <div className="embedded-page-container" style={{ overflow: "hidden" }}>
      <PageLayout backgroundColor={backgroundColor}>
        <div className="iframe-container">
          <iframe
            title={title}
            src={src}
            style={{
              position:"fixed",
              top:0,
              left:0,
              bottom:0,
              right:0,
              width:"100%",
              height: "100vh",
              border:"none",
              margin:0,
              padding:0,
              overflow:"hidden",
            }}
          >
            Your browser doesn't support this functionality or it's disabled. Please use a modern browser or enable this functionality.
          </iframe>
        </div>
      </PageLayout>
    </div>
  )
}

export default EmbeddedPage;